import { useState, useEffect, useCallback } from 'react';
import WebApp from '@twa-dev/sdk';

export const useServiceRequest = (serviceType, apiEndpoint) => {
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCreateClick = useCallback(async () => {
    setIsLoading(true);
    try {
      const user = WebApp.initDataUnsafe.user;
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          username: user.username,
          phone_number: user.phone_number,
          message: description,
          service_type: serviceType
        }),
      });
      
      const data = await response.json();
      
      if (data.status === 'success') {
        WebApp.close();
      } else {
        WebApp.showAlert(`Ошибка: ${data.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
      WebApp.showAlert('Произошла ошибка при отправке запроса');
    } finally {
      setIsLoading(false);
    }
  }, [description, serviceType, apiEndpoint]);

  useEffect(() => {
    WebApp.MainButton
      .setText('Отправить заявку')
      .show();

    return () => {
      WebApp.MainButton.hide();
    };
  }, []);

  useEffect(() => {
    if (description.trim() === '') {
      WebApp.MainButton.disable();
    } else {
      WebApp.MainButton.enable();
    }
  }, [description]);

  useEffect(() => {
    WebApp.MainButton.onClick(handleCreateClick);

    return () => {
      WebApp.MainButton.offClick(handleCreateClick);
    };
  }, [handleCreateClick]);

  return {
    description,
    isLoading,
    handleDescriptionChange
  };
};