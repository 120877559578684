import React from 'react';
import { Link } from 'react-router-dom';

import GenerationIcon from './other/GenerationIcon';
import UserIcon from './other/UserIcon';

const Header = () => {
  return (
      <header className="app-header">
        <Link to="/app" className="app-logo">SUNDIO</Link>
        <div className="app-icons">
          <GenerationIcon />
          <UserIcon />
        </div>
      </header>
  );
};

export default Header;