import React from 'react';
import WebApp from '@twa-dev/sdk';

import { useProfile } from '../fetch/ProfileProvider';

const UserIcon = () => {
  const { profile } = useProfile();
  const firstName = profile?.first_name || WebApp.initDataUnsafe.user?.first_name || 'U';
  const firstLetter = firstName.charAt(0).toUpperCase();

  return (
    <div className="user-icon">
      {profile?.avatar_thumbnail ? (
        <img src={`data:image/png;base64,${profile.avatar_thumbnail}`} alt={firstName} />
      ) : (
        <span>{firstLetter}</span>
      )}
    </div>
  );
};

export default UserIcon;