import React from 'react';
import WebApp from '@twa-dev/sdk';

const InAppBrowser = ({ url, onClose }) => {
  const theme = WebApp.colorScheme;

  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme === 'dark' ? '#2f2f2f' : 'white',
    zIndex: 1000,
  };

  const iframeStyle = {
    width: '100%',
    height: '100%',
    border: 'none',
  };

  return (
    <div style={containerStyle}>
      <iframe src={url} style={iframeStyle} />
    </div>
  );
};

export default InAppBrowser;