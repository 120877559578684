import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';

import GenerationIcon from './other/GenerationIcon';
import ImportantNotice from './other/ImportantNotice';
import { useLoader } from './other/LoaderContext';
import Help from './other/Help';

import baseSvg from '../assets/img/base.svg';
import premiumSvg from '../assets/img/premium.svg';
import premiumPlusSvg from '../assets/img/premium_plus.svg';

const TariffCard = ({ name, price, credits, isCurrent, icon, onUpgrade, upgradePrice, tariffKey, userTariff }) => {
  const showUpgradeButton = () => {
    if (userTariff === 'free') return tariffKey !== 'free';
    if (userTariff === 'base') return ['premium', 'premiumplus'].includes(tariffKey);
    if (userTariff === 'premium') return tariffKey === 'premiumplus';
    return false;
  };

  return (
    <div className="tariff-card">
      <div className="tarif-card-info">
        <h2>{name}</h2>
        <p className="price">₽ {price} / в мес.</p>
      </div>
      <div className="generations">
        <GenerationIcon icon={icon} count={credits} />
        <span className="generation-text">
          генераций <br/>в день
        </span>
      </div>
      {isCurrent ? (
        <div className="current-tariff">
          Ваш текущий тариф
        </div>
      ) : showUpgradeButton() && (
        <button className="connect-button" onClick={onUpgrade}>
          {userTariff === 'free' ? 'Подключить' : 'Перейти'}
        </button>
      )}
    </div>
  );
};

const Tariffs = () => {
  const [tariffs, setTariffs] = useState(null);
  const [userTariff, setUserTariff] = useState('free');
  const { setIsLoading } = useLoader();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTariffs = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`/api/tariffs?user_id=${WebApp.initDataUnsafe.user.id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTariffs(data);
        setUserTariff(Object.keys(data).find(key => data[key].is_current) || 'free');
      } catch (error) {
        console.error("Failed to fetch tariffs:", error);
        WebApp.showAlert("Не удалось загрузить информацию о тарифах. Пожалуйста, попробуйте позже.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchTariffs();
  }, [setIsLoading]);

  const handleUpgrade = async (tariff) => {
    setIsLoading(true);
    try {
      const response = await fetch('/api/upgrade_tariff', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: WebApp.initDataUnsafe.user.id, new_tariff: tariff })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.status === 'success') {
        navigate(`/app/payment?${data.payment_url.split('?')[1]}`);
      } else {
        WebApp.showAlert(data.message || "Произошла ошибка при обработке запроса");
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      WebApp.showAlert('Произошла ошибка при обработке запроса. Пожалуйста, попробуйте позже.');
      setIsLoading(false);
    }
  };

  if (!tariffs) return null;

  const tariffIcons = {
    base: baseSvg,
    premium: premiumSvg,
    premiumplus: premiumPlusSvg
  };

  const sortedTariffs = Object.entries(tariffs)
    .filter(([key]) => key !== 'free')
    .sort((a, b) => a[1].price - b[1].price);

  return (
    <div className="tariffs-container">
      <div className="menu-section-header">
        <h2>Тарифы</h2>
	    <Help>Проверка</Help>
        <div className="menu-section-line"></div>
      </div>
      <div className="tariffs-flex">
        {sortedTariffs.map(([key, tariff]) => (
          <TariffCard
            key={key}
            tariffKey={key}
            name={tariff.name}
            price={tariff.price}
            credits={tariff.credits}
            isCurrent={tariff.is_current}
            icon={tariffIcons[key]}
            onUpgrade={() => handleUpgrade(key)}
            upgradePrice={tariff.upgrade_price}
            userTariff={userTariff}
          />
        ))}
      </div>
      <ImportantNotice>
        Вы можете доплатить и повысить тариф в любой момент. Дата окончания тарифа останется неизменной. <br/><br/>Если Вы хотите понизить тариф - Вам необходимо дождаться завершения действия подписки и перейти на новый тариф.
      </ImportantNotice>
      <div className="tariffs-information">
          <p>Весь функционал бота доступен на всех тарифах - отличается только кол-во генераций</p>
          <p>Используйте только оригинальное приложение Telegram для Вашей платформы. На сторонних модах работа оплаты не гарантируется</p>
          <p>Онлайн оплата доступна только пользователям из РФ. Если Вы хотите оплатить из другой страны - пожалуйста, свяжитесь с поддержкой <a href="https://t.me/sundio_support">@sundio_support</a></p>
      </div>
    </div>
  );
};

export default Tariffs;