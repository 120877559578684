// src/components/search_voice/SearchVoice.js
import React, { useState, useEffect, useCallback } from 'react';
import WebApp from '@twa-dev/sdk';

import filterIcon from '../../assets/img/filter.svg';

import SearchBar from './SearchBar';
import Tag from './Tag';
import PerformerList from './PerformerList';
import Loader from '../other/Loader';


function SearchVoice() {
  const [performers, setPerformers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [showTagPopup, setShowTagPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [messageId, setMessageId] = useState(null);

  const fetchPerformers = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      let url = `/api/search?query=${searchQuery}`;
      if (selectedTags.length > 0) {
        url += `&tags=${selectedTags.join(',')}`;
      }
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      let data = await response.json();
      if (!Array.isArray(data)) {
        throw new Error('Received data is not an array');
      }
      
      const uniquePerformers = data.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      
      setPerformers(uniquePerformers);
    } catch (error) {
      setError('Failed to load performers. Please try again.');
      setPerformers([]);
    } finally {
      setIsLoading(false);
    }
  }, [searchQuery, selectedTags]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const msgId = urlParams.get('message_id');
    setMessageId(msgId);

    if (msgId) {
      fetch('/api/set_message_id', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: WebApp.initDataUnsafe.user.id,
          message_id: parseInt(msgId),
        }),
      }).catch((error) => {
        console.error('Error saving message_id:', error);
      });
    }

    fetchPerformers();
  }, [fetchPerformers]);

  const handlePerformerSelect = useCallback(async (performer) => {
    try {
      if (!messageId) {
        throw new Error('Message ID not found');
      }
      
      const response = await fetch('/api/submit_performer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: WebApp.initDataUnsafe.user.id,
          performer_id: performer.id,
          performer_name: performer.name,
          message_id: messageId
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      WebApp.close();
    } catch (error) {
      console.error('Error in handlePerformerSelect:', error);
      alert('An error occurred. Please try again.');
    }
  }, [messageId]);

  return (
    <div className="SearchVoice">
      <SearchBar 
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onSearch={fetchPerformers}
      />
      {!showTagPopup && (
        <button className="filter-button" onClick={() => setShowTagPopup(true)}>
          <img src={filterIcon} alt="Filter" />
        </button>
      )}
      {showTagPopup && (
        <Tag 
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          onClose={() => setShowTagPopup(false)}
        />
      )}
      <div className="performer-list-container">
        {isLoading && <Loader />}
        {error && <div className="error-message">{error}</div>}
        {!isLoading && !error && <PerformerList performers={performers} onSelect={handlePerformerSelect} />}
      </div>
    </div>
  );
}

export default SearchVoice;