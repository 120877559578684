import React from 'react';
import WebApp from '@twa-dev/sdk';

function PerformerList({ performers, onSelect }) {
  if (!Array.isArray(performers)) {
    console.error('performers is not an array:', performers);
    return <div>Error: Unable to display performers</div>;
  }

  const handleSelect = async (e, performer) => {
    e.preventDefault(); // Предотвращаем действие по умолчанию
    console.log('Performer selected:', performer);

    try {
      const userData = await fetch('/api/get_user_data?user_id=' + WebApp.initDataUnsafe.user.id);
      if (!userData.ok) {
        throw new Error(`HTTP error! status: ${userData.status}`);
      }
      const { message_id } = await userData.json();

      if (!message_id) {
        console.error('message_id not found in user data');
        alert('Ошибка: не найден идентификатор сообщения. Пожалуйста, попробуйте еще раз.');
        return;
      }

      const response = await fetch('/api/submit_performer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: WebApp.initDataUnsafe.user.id,
          performer_id: performer.id,
          performer_name: performer.name,
          message_id: message_id
        }),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.status === 'success') {
          WebApp.close();
        } else {
          throw new Error(result.message || 'Unknown error occurred');
        }
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert(`Произошла ошибка: ${error.message}`);
    }
  };

  return (
    <div className="performer-list">
      {performers.length === 0 ? (
	  <>
		<div className="error-smile">😞</div>
        <p className="error-voice-search">Голосов не найдено.<br/>Проверьте настройки фильтрации</p>
	  </>
      ) : (
        performers.map((performer, index) => {
          if (!performer || typeof performer !== 'object') {
            console.error('Invalid performer object:', performer);
            return null;
          }
          return (
            <button 
              key={`${performer.id}-${index}`} // Используем комбинацию id и индекса для уникального ключа
              className="performer-item"
              onClick={(e) => handleSelect(e, performer)}
            >
              <h3>{performer.name || 'Unnamed Performer'}</h3>
              <p>{Array.isArray(performer.tags) ? performer.tags.join(', ') : 'No tags'}</p>
            </button>
          );
        })
      )}
    </div>
  );
}

export default React.memo(PerformerList);