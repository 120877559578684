import React from 'react';

import searchIcon from '../../assets/img/search_icon_125165.svg';

function SearchBar({ searchQuery, setSearchQuery, onSearch }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch();
  };

  return (
    <form onSubmit={handleSubmit} className="search-bar">
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Поиск голоса.."
      />
      <button type="submit" className="search-button">
        <img src={searchIcon} alt="Search" className="search-icon" />
      </button>
    </form>
  );
}

export default SearchBar;