import React from 'react';
import { useLocation } from 'react-router-dom';
import { useServiceRequest } from './useServiceRequest';
import ImportantNotice from '../other/ImportantNotice';

const serviceConfig = {
  new_voice: {
    title: "Заявка на добавление голоса",
    description: "<p>Мы добавляем модели голосов, которые есть в общем доступе. Если у нас не получится их найти - голос добавлен не будет.</p>",
    placeholder: "Напиши, голос какого исполнителя\\персонажа Вы хотели бы Видеть в боте...",
    apiEndpoint: "/api/new_voice",
    rows: 5,
    importantNotice: "<p>Добавляем только известных персонажей. Чтобы добавить лично ВАШ голос в базу данных - нужно сначала обучить модель.</p><p>Посмотрите раздел <a href='/app/uslugi/'>УСЛУГИ</a> и выберите пункт обучения модели</p>",
    url: "/app/uslugi/new_voice"
  },
  neurocover: {
    title: "Заявка на нейрокавер",
    description: "<p>Запишем трек с помощью нейросетей, обработаем и изменим на выбранный Вами голос. Оставьте заявку и с Вами свяжутся в течении 15мин!</p>",
    placeholder: "Опишите, что хотели бы видеть в нейрокавере...",
    apiEndpoint: "/api/submit_service_request",
    rows: 7,
    importantNotice: "<p>Чтобы исполнитель мог с Вами связаться - проверьте, установлен ли у Вас @логин в настройках Telegram</p>",
    url: "/app/uslugi/neurocover"
  },
  live_neurocover: {
    title: "Заявка на живой нейрокавер",
    description: "<p>'Ай, гитарист! (Андрей)' сам споет голосами легендартных певцов. Авторское исполнение для поклонников Андрея.</p>",
    placeholder: "Опишите, что хотели бы видеть в нейрокавере...",
    apiEndpoint: "/api/submit_service_request",
    rows: 6,
    importantNotice: "<p>Чтобы исполнитель мог с Вами связаться - проверьте, установлен ли у Вас @логин в настройках Telegram</p>",
    url: "/app/uslugi/live_neurocover"
  },
  model_training: {
    title: "Заявка на обучение модели",
    description: "<p>Обучим для Вас голосовую модель. Сами подготовим и обработаем датасет, проведем все корректировки и вышлем Вам готовые файлы. При желании - голос можно будет добавить в бота</p>",
    placeholder: "Опишите ваш запрос здесь...",
    apiEndpoint: "/api/submit_service_request",
    rows: 8,
    importantNotice: "<p>Чтобы исполнитель мог с Вами связаться - проверьте, установлен ли у Вас @логин в настройках Telegram</p>",
    url: "/app/uslugi/model_training"
  }
};

const AllServices = () => {
  const location = useLocation();
  const serviceType = location.pathname.split('/').pop();
  const config = serviceConfig[serviceType];

  const { description, isLoading, handleDescriptionChange } = useServiceRequest(serviceType, config.apiEndpoint);

  if (!config) {
    return <div>Service not found</div>;
  }

  return (
    <div className="contact-us">
      <h1>{config.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: config.description }} />
      <div className="form-container">
        <textarea 
          value={description}
          onChange={handleDescriptionChange}
          placeholder={config.placeholder}
          rows={config.rows}
        />
        <div className="form-footer">
          <div className="char-count">
            {description.length} / 1000
          </div>
        </div>
      </div>
      <ImportantNotice>
        <div dangerouslySetInnerHTML={{ __html: config.importantNotice }} />
      </ImportantNotice>
      {isLoading && <div className="loader-container"><div className="loader"></div></div>}
    </div>
  );
};

export default AllServices;