import React, { createContext, useState, useEffect, useContext } from 'react';
import WebApp from '@twa-dev/sdk';

const ProfileContext = createContext();

export const useProfile = () => useContext(ProfileContext);

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`/api/get_profile?user_id=${WebApp.initDataUnsafe.user.id}`);
        if (response.ok) {
          const data = await response.json();
          console.log('Полученные данные профиля:', data);  // Добавлено логирование
          setProfile(data);
        } else {
          console.error('Failed to fetch profile:', await response.text());
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, []);

  return (
    <ProfileContext.Provider value={{ profile, isLoading }}>
      {children}
    </ProfileContext.Provider>
  );
};