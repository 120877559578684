// src/components/search_voice/Tag.js
import React, { useState, useEffect } from 'react';

import Popup from '../other/Popup';

function Tag({ selectedTags, setSelectedTags, onClose }) {
  const [allTags, setAllTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await fetch('/api/tags');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setAllTags(data);
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    fetchTags();
  }, []);

  const toggleTag = (tag) => {
    setSelectedTags(prevTags => {
      if (prevTags.includes(tag)) {
        return prevTags.filter(t => t !== tag);
      } else {
        return [...prevTags, tag];
      }
    });
  };

  return (
    <Popup onClose={onClose}>
      <div className="tag-scroll">
        <div className="tag-grid">
          {allTags.map(tag => (
            <button
              key={tag}
              onClick={() => toggleTag(tag)}
              className={selectedTags.includes(tag) ? 'selected' : ''}
            >
              {tag}
            </button>
          ))}
        </div>
      </div>
    </Popup>
  );
}

export default React.memo(Tag);