import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Header from './Header';
import Main from './Main';
import Payment from './Payment';
import Tariffs from './Tariffs';

import SearchVoice from './search_voice/SearchVoice';
import SimpleGeneration from './generation/SimpleGeneration';
import CustomGeneration from './generation/CustomGeneration';
import AudioinputGeneration from './generation/AudioinputGeneration';
import AllServices from './uslugi/AllServices';
import { ProfileProvider } from './fetch/ProfileProvider';
import { LoaderProvider } from './other/LoaderContext';
import { ThemeProvider } from './other/ThemeProvider';
import Loader from './other/Loader';

function App() {
  return (
    <ThemeProvider>
      <LoaderProvider>
        <ProfileProvider>
          <Header />
          <Loader />
          <div className="App">
            <Routes>
              <Route path="/app" element={<Main />} />
              <Route path="/app/search_voice" element={<SearchVoice />} />
              <Route path="/app/payment" element={<Payment />} />
              <Route path="/app/simple_generation" element={<SimpleGeneration />} />
              <Route path="/app/custom_generation" element={<CustomGeneration />} />
              <Route path="/app/audioinput_generation" element={<AudioinputGeneration />} />
              <Route path="/app/tariffs" element={<Tariffs />} />
              <Route path="/app/uslugi/:serviceType" element={<AllServices />} />
            </Routes>
          </div>
        </ProfileProvider>
      </LoaderProvider>
    </ThemeProvider>
  );
}

export default App;