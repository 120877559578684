import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';

import GenerationForm from './GenerationForm';
import InAppBrowser from '../other/InAppBrowser';

const AudioinputGeneration = () => {
  const [browserUrl, setBrowserUrl] = useState(null);
  const [audioDuration, setAudioDuration] = useState(0);
  const [clipId, setClipId] = useState(null);
  const [cookie, setCookie] = useState(null);
  const [cookieId, setCookieId] = useState(null);
  const location = useLocation();

  const handleLinkClick = (e, url) => {
    e.preventDefault();
    setBrowserUrl(url);
    WebApp.BackButton.show();
  };

  const closeBrowser = () => {
    setBrowserUrl(null);
    WebApp.BackButton.hide();
  };

  useEffect(() => {
    WebApp.BackButton.onClick(closeBrowser);
    
    // Получаем параметры из URL
    const searchParams = new URLSearchParams(location.search);
    const duration = searchParams.get('duration');
    const clipIdFromUrl = searchParams.get('clip_id');
    const cookieFromUrl = searchParams.get('cookie');
    const cookieIdFromUrl = searchParams.get('cookie_id');
    
    if (duration) {
      setAudioDuration(parseInt(duration, 10));
    }
    
    if (clipIdFromUrl) {
      setClipId(clipIdFromUrl);
    }

    if (cookieFromUrl) {
      setCookie(cookieFromUrl);
    }

    if (cookieIdFromUrl) {
      setCookieId(cookieIdFromUrl);
    }

    return () => {
      WebApp.BackButton.offClick(closeBrowser);
    };
  }, [location]);

  const fields = [
    {
      titleGeneration: 'Аудиовход'
    },
    {
      id: 'description',
      label: 'Описание трека',
      maxLength: 3000,
      containerClass: 'description-container',
      placeholder: 'Введите текст песни',
      tooltip: (
        <>
          Не указывайте здесь стили и жанры трека!<br/>
          Здесь необходимо указывать только описание трека или его текст, а также теги разметки<br/><br/>
          <a href="https://music.bloom-digital.ru/tegi-i-razmetka-treka-v-suno/" onClick={(e) => handleLinkClick(e, "https://music.bloom-digital.ru/tegi-i-razmetka-treka-v-suno/")}>Подробнее про разметку</a>
        </>
      ),
      rows: 10
    },
    {
      id: 'style',
      label: 'Стили и жанры',
      maxLength: 120,
      containerClass: 'style-container',
      placeholder: 'Введите стили и жанры песни',
      tooltip: (
        <>
          Если хотите указать несколько - указывайте через запятую<br/><br/>
          <a href="https://music.bloom-digital.ru/spisok-zhanrov-suno/" onClick={(e) => handleLinkClick(e, "https://music.bloom-digital.ru/spisok-zhanrov-suno/")}>Подробнее про жанры и стили</a>
        </>
      ),
      rows: 2
    },
    {
      id: 'title',
      label: 'Название',
      maxLength: 80,
      containerClass: 'title-container',
      placeholder: 'Введите название песни',
      tooltip: 'Укажите название песни, которое Вы хотите видеть после генерации',
      rows: 1
    },
    {
      id: 'startTime',
      label: 'С какой секунды начать?',
      maxLength: 2,
      containerClass: 'start-time-container',
      placeholder: `Отправьте число от 1 до ${audioDuration}`,
      tooltip: (
        <>
          <b>Длина вашего аудио:</b> {audioDuration} секунд.<br/><br/> 
          Введите число, с какой секунды начать интерпретировать загруженное аудио.
        </>
      ),
      rows: 1,
      type: 'number',
      min: 1,
      max: audioDuration
    }
  ];

  const handleSubmit = useCallback(async (formData) => {
    try {
      const response = await fetch('/api/start_audioinput_generation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: WebApp.initDataUnsafe.user.id,
          description: formData.description,
          style: formData.style,
          title: formData.title,
          start_time: formData.startTime,
          clip_id: clipId,
          cookie: cookie,
          cookie_id: cookieId
        }),
      });
      
      const data = await response.json();
      
      if (data.status !== 'success') {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      WebApp.showAlert(error.message);
    }
  }, [clipId, cookie, cookieId]);

  return (
    <>
      <GenerationForm
        fields={fields}
        onSubmit={handleSubmit}
        showResetButton={true}
        resetButtonLocation="outside"
        storagePrefix="audioinput_"
      />
      {browserUrl && <InAppBrowser url={browserUrl} onClose={closeBrowser} />}
    </>
  );
};

export default AudioinputGeneration;