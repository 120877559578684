import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import ImportantNotice from './other/ImportantNotice';
import GenerationIcon from './other/GenerationIcon';
import Help from './other/Help';
import { useProfile } from './fetch/ProfileProvider';
import InAppBrowser from './other/InAppBrowser';

import tgIcon from '../assets/img/tg.svg';
import arrowIcon from '../assets/img/arrow.svg';
import simpleGenerationIcon from '../assets/img/SimpleGeneration.svg';
import customGenerationIcon from '../assets/img/CustomGeneration.svg';
import audioGenerationIcon from '../assets/img/AudioGeneration.svg';
import voiceChangeTrackIcon from '../assets/img/1voice_change_track.svg';
import voiceChangeVoiceIcon from '../assets/img/2voice_change_voice.svg';
import textToSpeachIcon from '../assets/img/3text_to_speech.svg';
import addVoiceIcon from '../assets/img/4add_voice.svg';
import vocalSeparationIcon from '../assets/img/5vocal_separation.svg';
import profileIcon from '../assets/img/6profile.svg';
import tariffsIcon from '../assets/img/7tariffs.svg';
import serviceIcon from '../assets/img/8service.svg';
import docsIcon from '../assets/img/9docs.svg';
import supportIcon from '../assets/img/10support.svg';

const MenuItem = ({ icon, title, description, link }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (link.startsWith('http')) {
      window.open(link, '_blank');
    } else {
      navigate(link);
    }
  };

  return (
  <div className="menu-itemssss">
    <div className="menu-item" onClick={handleClick}>
      <img src={icon} alt={title} className="menu-icon" />
      <div className="menu-text">
        <h3>{title}</h3>
      </div>
      {description && <span className="menu-description">{description}</span>}
      <img src={arrowIcon} alt="Arrow" className="arrow-icon" />
    </div>
	</div>
  );
};

const MenuSection = ({ title, items }) => (
  <div className="menu-section">
    <div className="menu-section-header">
      <h2>{title}</h2>
	  <Help>Проверка</Help>
      <div className="menu-section-line"></div>
    </div>
    {items.map((item, index) => (
      <MenuItem key={index} {...item} />
    ))}
  </div>
);

const Main = () => {
  const [showNotice, setShowNotice] = useState(true);
  const [browserUrl, setBrowserUrl] = useState(null);
  const { profile, isLoading } = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    const noticeShown = sessionStorage.getItem('noticeShown');
    if (noticeShown) {
      setShowNotice(false);
    }
  }, []);

  const handleCloseNotice = () => {
    setShowNotice(false);
    sessionStorage.setItem('noticeShown', 'true');
  };

  const handleLinkClick = (e, url) => {
    e && e.preventDefault();
    setBrowserUrl(url);
    WebApp.BackButton.show();
  };

  const closeBrowser = () => {
    setBrowserUrl(null);
    WebApp.BackButton.hide();
  };
  
  const handleTelegramClick = () => {
    WebApp.openTelegramLink('https://t.me/ainetworki');
  };

  useEffect(() => {
    WebApp.BackButton.onClick(closeBrowser);

    return () => {
      WebApp.BackButton.offClick(closeBrowser);
    };
  }, []);

  const menuSections = [
    {
      title: 'ГЕНЕРАЦИЯ МУЗЫКИ',
      items: [
        {
          icon: simpleGenerationIcon,
          title: 'Простая генерация',
          description: 'Создаст текст',
          link: '/app/simple_generation'
        },
        {
          icon: customGenerationIcon,
          title: 'Кастомная генерация',
          description: 'Свой текст',
          link: '/app/custom_generation'
        },
        {
          icon: audioGenerationIcon,
          title: 'Генерация со своим аудио',
          description: 'Свой бит',
          link: '/app/audioinput_generation'
        }
      ]
    },
    {
      title: 'ЗАМЕНА ГОЛОСА',
      items: [
        {
          icon: voiceChangeTrackIcon,
          title: 'Замена голоса в песне',
          link: '/app/voice_replacement'
        },
        {
          icon: voiceChangeVoiceIcon,
          title: 'Замена голоса в голосовом сообщении',
          link: '/app/voice_message_replacement'
        },
        {
          icon: textToSpeachIcon,
          title: 'Текст в речь',
          description: 'Beta',
          link: '/app/text_to_speech'
        },
        {
          icon: addVoiceIcon,
          title: 'Добавить новый голос',
          description: 'Заявка',
          link: '/app/uslugi/new_voice'
        }
      ]
    },
    {
      title: 'ИНСТРУМЕНТЫ',
      items: [
        {
          icon: vocalSeparationIcon,
          title: 'Вокальный разделитель',
          link: '/app/vocal_separator'
        }
      ]
    },
    {
      title: 'ИНФОРМАЦИЯ',
      items: [
        {
          icon: profileIcon,
          title: 'Профиль',
          link: '/app/profiles'
        },
        {
          icon: tariffsIcon,
          title: 'Тарифы',
          description: isLoading ? 'Загрузка...' : (profile?.tariff_name || 'Не определен'),
          link: '/app/tariffs'
        },
        {
          icon: serviceIcon,
          title: 'Услуги',
          link: '/app/uslugi/'
        },
        {
          icon: docsIcon,
          title: 'Инструкции',
          link: '/app/docs'
        },
        {
          icon: supportIcon,
          title: 'Поддержка',
          link: '/app/support'
        }
      ]
    }
  ];

  return (
    <div className="main-container">
      {showNotice && (
        <ImportantNotice onClose={handleCloseNotice}>
          Прежде чем начать, ознакомьтесь с <a href="https://music.bloom-digital.ru/category/docs/" onClick={(e) => handleLinkClick(e, "https://music.bloom-digital.ru/category/docs/")}>инструкциями</a> для более качественной генерации
        </ImportantNotice>
      )}
      <div className="info-blocks">
        <div className="info-block" onClick={() => navigate('/app/tariffs')}>
          <div>Осталось генераций</div>
          <GenerationIcon count={profile?.credit_count} />
        </div>
        <div className="info-block" onClick={handleTelegramClick}>
          <div>Наш телеграм</div>
          <img src={tgIcon} alt="Telegram" className="tg-icon" />
        </div>
      </div>
      {menuSections.map((section, index) => (
        <MenuSection key={index} {...section} />
      ))}
      {browserUrl && <InAppBrowser url={browserUrl} onClose={closeBrowser} />}
    </div>
  );
};

export default Main;