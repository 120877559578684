import React from 'react';

import premiumplusSvg from '../../assets/img/premium_plus.svg';

import { useProfile } from '../fetch/ProfileProvider';

const GenerationIcon = ({ icon = premiumplusSvg, count }) => {
  const { profile } = useProfile();
  const displayCount = count !== undefined ? count : profile?.credit_count || 0;

  return (
    <div className="generation-icon">
      <img src={icon} alt="Generation icon" className="tariff-icon" />
      <span className="generation-count">{displayCount}</span>
    </div>
  );
};

export default GenerationIcon;