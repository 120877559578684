import React, { createContext, useState, useEffect, useContext } from 'react';
import WebApp from '@twa-dev/sdk';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const useThemeClass = (baseClass) => {
  const { colorScheme } = useTheme();
  return `${baseClass} ${colorScheme === 'light' ? `${baseClass}-invert` : ''}`;
};

export const ThemeProvider = ({ children }) => {
  const [colorScheme, setColorScheme] = useState(WebApp.colorScheme);

  useEffect(() => {
    const handleThemeChange = () => {
      setColorScheme(WebApp.colorScheme);
    };

    WebApp.onEvent('themeChanged', handleThemeChange);

    return () => {
      WebApp.offEvent('themeChanged', handleThemeChange);
    };
  }, []);

  return (
    <ThemeContext.Provider value={{ colorScheme }}>
      {children}
    </ThemeContext.Provider>
  );
};