import React, { useCallback } from 'react';
import WebApp from '@twa-dev/sdk';

import GenerationForm from './GenerationForm';
import InAppBrowser from '../other/InAppBrowser';

const CustomGeneration = () => {
  const [browserUrl, setBrowserUrl] = React.useState(null);

  const handleLinkClick = (e, url) => {
    e.preventDefault();
    setBrowserUrl(url);
    WebApp.BackButton.show();
  };

  const closeBrowser = () => {
    setBrowserUrl(null);
    WebApp.BackButton.hide();
  };

  React.useEffect(() => {
    WebApp.BackButton.onClick(closeBrowser);

    return () => {
      WebApp.BackButton.offClick(closeBrowser);
    };
  }, []);

  const fields = [
    {
      id: 'description',
      label: 'Текст песни',
      maxLength: 3000,
      containerClass: 'description-container',
      placeholder: 'Введите текст песни',
      tooltip: (
        <>
          Не указывайте здесь стили и жанры трека! Здесь необходимо указывать только текст песни, а также теги разметки<br/><br/>
          <a href="https://music.bloom-digital.ru/tegi-i-razmetka-treka-v-suno/" onClick={(e) => handleLinkClick(e, "https://music.bloom-digital.ru/tegi-i-razmetka-treka-v-suno/")}>Подробнее про разметку</a>
        </>
      ),
      rows: 10
    },
    {
      id: 'style',
      label: 'Стили и жанры',
      maxLength: 120,
      containerClass: 'style-container',
      placeholder: 'Введите стили и жанры песни',
      tooltip: (
        <>
          Если хотите указать несколько - указывайте через запятую<br/><br/>
          <a href="https://music.bloom-digital.ru/spisok-zhanrov-suno/" onClick={(e) => handleLinkClick(e, "https://music.bloom-digital.ru/spisok-zhanrov-suno/")}>Подробнее про жанры и стили</a>
        </>
      ),
      rows: 2
    },
    {
      id: 'title',
      label: 'Название',
      maxLength: 80,
      containerClass: 'title-container',
      placeholder: 'Введите название песни',
      tooltip: 'Укажите название песни, которое Вы хотите видеть после генерации',
      rows: 1
    }
  ];

  const handleSubmit = useCallback(async (formData) => {
    try {
      const response = await fetch('/api/start_custom_generation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: WebApp.initDataUnsafe.user.id,
          description: formData.description,
          style: formData.style,
          title: formData.title
        }),
      });
      
      const data = await response.json();
      
      if (data.status !== 'success') {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      WebApp.showAlert(error.message);
    }
  }, []);

  return (
    <>
      <GenerationForm
        fields={fields}
        onSubmit={handleSubmit}
        showResetButton={true}
        resetButtonLocation="outside"
        storagePrefix="custom_"
      />
      {browserUrl && <InAppBrowser url={browserUrl} onClose={closeBrowser} />}
    </>
  );
};

export default CustomGeneration;