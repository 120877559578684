import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';

import { useLoader } from './other/LoaderContext';

function getTelegramThemeColors() {
  const root = document.documentElement;
  const computedStyle = getComputedStyle(root);

  return {
    background: computedStyle.getPropertyValue('--tg-theme-bg-color').trim() || '#1e1e1e',
    border: computedStyle.getPropertyValue('--tg-theme-section-separator-color').trim() || '#555555',
    control_primary: computedStyle.getPropertyValue('--tg-theme-button-color').trim() || '#047bff',
    control_primary_content: computedStyle.getPropertyValue('--tg-theme-button-text-color').trim() || '#ffffff',
    control_secondary: computedStyle.getPropertyValue('--tg-theme-secondary-bg-color').trim() || '#333333'
  };
}

function Payment() {
  const [error, setError] = useState(null);
  const { isLoading, setIsLoading } = useLoader();
  const [isWidgetReady, setIsWidgetReady] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const checkoutRef = useRef(null);
  const initializeAttemptedRef = useRef(false);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(goBack);

    return () => {
      WebApp.BackButton.offClick(goBack);
      WebApp.BackButton.hide();
    };
  }, [goBack]);

  const initializePayment = useCallback(async () => {
    if (initializeAttemptedRef.current) return;
    initializeAttemptedRef.current = true;

    try {
      const script = document.createElement('script');
      script.src = 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js';
      script.async = true;
      document.body.appendChild(script);

      await new Promise((resolve) => {
        script.onload = resolve;
      });

      const queryParams = new URLSearchParams(location.search);
      const amount = queryParams.get('amount');
      const userId = queryParams.get('user_id');
      const tariff = queryParams.get('tariff');
      const action = queryParams.get('action');

      if (!amount || !userId || !tariff || !action) {
        throw new Error('Отсутствуют необходимые параметры.');
      }

      const response = await fetch('/api/create_payment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount: parseFloat(amount), user_id: parseInt(userId), tariff, action }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (!data.confirmation_token) {
        throw new Error('Не получен токен подтверждения от сервера');
      }

      const themeColors = getTelegramThemeColors();

      checkoutRef.current = new window.YooMoneyCheckoutWidget({
        confirmation_token: data.confirmation_token,
        return_url: window.location.origin + '/app/payment?status=success',
        customization: {
          colors: themeColors
        },
        error_callback: function(error) {
          console.error('YooKassa widget error:', error);
          setError('Произошла ошибка при оплате');
          setIsLoading(false);
          WebApp.close();
        }
      });

      const paymentForm = document.getElementById('payment-form');
      if (!paymentForm) {
        throw new Error('Элемент payment-form не найден');
      }

      await checkoutRef.current.render('payment-form');
      
      setIsLoading(false);
      setIsWidgetReady(true);

      checkoutRef.current.on('success', async () => {
        try {
          const successResponse = await fetch('/api/payment_success', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              user_id: parseInt(userId),
              action: action,
              tariff: tariff,
              amount: parseFloat(amount),
              transaction_id: data.confirmation_token
            }),
          });

          if (!successResponse.ok) {
            throw new Error(`HTTP error! status: ${successResponse.status}`);
          }

          const successData = await successResponse.json();

          if (successData.status === 'success') {
            WebApp.sendData(JSON.stringify({
              type: 'payment_success',
              message: successData.message
            }));
            WebApp.close();
          } else {
            throw new Error(successData.message);
          }
        } catch (error) {
          console.error('Error processing payment:', error);
          setError('Произошла ошибка при обработке платежа');
          WebApp.close();
        }
      });

    } catch (error) {
      console.error('Error:', error);
      setError(`Ошибка: ${error.message}`);
      setIsLoading(false);
      WebApp.close();
    }
  }, [location.search, setIsLoading]);

  useEffect(() => {
    initializePayment();
  }, [initializePayment]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('status') === 'success') {
      WebApp.close();
    }
  }, [location.search]);

  if (error) {
    return (
      <div className="error" style={{ textAlign: 'center', padding: '20px' }}>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <>
      {isLoading && <div className="loader-container"><div className="loader"></div></div>}
      <div id="payment-form" style={{ display: isWidgetReady ? 'block' : 'none' }}></div>
    </>
  );
}

export default Payment;