import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import importantSvg from '../../assets/img/important.svg';
import closeSvg from '../../assets/img/close.svg';

const ImportantNotice = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isMounted, setIsMounted] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const noticeHidden = sessionStorage.getItem(`importantNoticeHidden_${location.pathname}`);
    if (noticeHidden) {
      setIsVisible(false);
      setIsMounted(false);
    } else {
      setIsVisible(true);
      setIsMounted(true);
    }
  }, [location.pathname]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setIsMounted(false);
      sessionStorage.setItem(`importantNoticeHidden_${location.pathname}`, 'true');
    }, 500); // Время анимации
  };

  if (!isMounted) {
    return null;
  }

  return (
    <div className={`important-container ${isVisible ? '' : 'closing'}`}>
      <button onClick={handleClose} className="important-close-button">
        <img src={closeSvg} alt="Close" />
      </button>
      <div className="important-image">
        <img src={importantSvg} alt="Important" />
      </div>
      <div className="important-text">
        {children}
      </div>
    </div>
  );
};

export default ImportantNotice;